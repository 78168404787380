import React, { useState } from 'react'
import axios from '../api/axios'
import styles from './DeleteLesson.module.css'
import ConfirmationModal from './ConfirmModal'

const DeleteLesson = ({ lessonUuid, onDeleted, setQuizData, setVideoData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleDeleteClick = () => {
    setIsModalOpen(true)
  }

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api/admin/v1/learn/lesson/${lessonUuid}`)
      if (onDeleted) {
        onDeleted()
      }
      lessonUuid = null
      setVideoData([])
      setQuizData([])
      setIsModalOpen(false)
    } catch (error) {
      alert('Ошибка при удалении урока. ', error)
      setIsModalOpen(false)
    }
  }

  return (
    <div >
      <button className={styles.lessonDeleteBtn} onClick={handleDeleteClick}>
        Удалить урок
      </button>

      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleConfirmDelete}
      >
        <p>Вы уверены, что хотите удалить этот урок?</p>
      </ConfirmationModal>
    </div>
  )
}

export default DeleteLesson

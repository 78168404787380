/* eslint-disable */
import React, { useState, useEffect } from 'react';
import axios from '../api/axios';
import DataTable from '../components/DataTable';
import Pagination from '@mui/material/Pagination';
import { useNavigate, useLocation } from 'react-router-dom';
import './UserList.css';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const limit = 10;

  const query = new URLSearchParams(location.search);
  const pageFromUrl = parseInt(query.get('page') || '1', 10);
  const zeroIndexedPage = pageFromUrl - 1;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const offset = zeroIndexedPage * limit;
        const response = await axios.get(`/api/admin/v1/users?offset=${offset}&limit=${limit}`);
        setUsers(response.data.users);
        setTotalCount(response.data.count);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchData();
  }, [zeroIndexedPage]);

  const handleChangePage = (event, value) => {
    navigate(`?page=${value}`);
  };

  const totalPages = Math.ceil(totalCount / limit);

  // const DataGridRows = Array.isArray(users)
  //   ? users.map(user => ({
  //     id: user.id,
  //     firstName: user.first_name,
  //     lastName: user.last_name,
  //     email: user.email,
  //     time: '00:00:00',
  //     lastTime: '00:00'
  //   }))
  //   : [];
  const DataGridRows = Array.isArray(users)
    ? users.map(user => ({
      uuid: user.uuid,
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      role: user.role,
      email: user.email,
      time: '00:00:00',
      lastTime: '00:00'
    }))
    : [];

  const filteredUsers = DataGridRows.filter(user =>
    user.firstName.toLowerCase().includes(search.toLowerCase()) ||
    user.lastName.toLowerCase().includes(search.toLowerCase()) ||
    user.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div className="users">
      {/* Search input and other components here */}
      {/* ... */}
      <div className='container'>
        <div className="paginationContainer">
          <Pagination
            count={totalPages}
            page={zeroIndexedPage + 1}
            onChange={handleChangePage}
            showFirstButton
            showLastButton
          />
        </div>
        {filteredUsers.length > 0 ? <DataTable usersData={filteredUsers} /> : 'No results'}
      </div>
    </div>
  );
};

export default UserList;
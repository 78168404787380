import React, { useState, useEffect } from 'react'
import axios from '../api/axios'
import { useParams } from 'react-router-dom'

const UserDetail = () => {
  const { id } = useParams()

  const [user, setUser] = useState(null)
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: ''
  })

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/api/admin/v1/user/${id}`)
        setUser(response.data)
        setFormData({
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          email: response.data.email
        })
      } catch (error) {
        console.error('Error fetching user detail:', error.response?.data?.message)
      }
    }

    fetchUser()
  }, [id])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prevState => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await axios.put(`/api/admin/v1/user/${id}`, formData)
      alert('User details updated successfully.')
    } catch (error) {
      console.error('Error updating user details:', error.response?.data?.message)
    }
  }

  if (!user) return <div>Loading...</div>

  return (
    <div>
      <h2>Редактировать данные пользователя</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>ID:</label>
          <span>{user.id}</span>
        </div>
        <div>
          <label>Имя:</label>
          <input
            type="text"
            name="first_name"
            value={formData.first_name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Фамилия:</label>
          <input
            type="text"
            name="last_name"
            value={formData.last_name}
            onChange={handleInputChange}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        {/* <div>Школа:</div> */}
        <button type="submit">Сохранить изменения</button>
      </form>
    </div>
  )
}

export default UserDetail

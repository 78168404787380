import React, { useState, useEffect } from 'react'
import axios from '../api/axios'
import { useParams } from 'react-router-dom'
import styles from './SharedVideo.module.css'
import DownloadModal from '../components/DownloadModal'
import heartIcon from '../assets/heart_icon.png'
import shareIcon from '../assets/share_icon.png'

const SharedVideo = () => {
  const [videoData, setVideoData] = useState(null)
  const [showDownloadModal, setShowDownloadModal] = useState(false)
  const { id } = useParams() // Get the 'id' from the URL

  useEffect(() => {
    document.body.style.backgroundColor = '#353535'
    // Function to fetch data
    const fetchData = async () => {
      try {
        const quality = 'small_240'
        const response = await axios.get(`api/public/v1/content/facts/${id}/${quality}`)
        setVideoData(response.data)
      } catch (error) {
        console.error('Error fetching video data:', error)
      }
    }

    fetchData()
  }, [id])

  const handleVideoEnd = () => {
    setShowDownloadModal(true)
  }

  if (!videoData) {
    return <div>Loading...</div>
  }

  return (
    <section className={styles.sharedVideoContainer}>
      <div className={styles.videoContainer}>
        <header className={styles.logo}>CAAC</header>
        <div className={styles.videoPlayer}>
          <video src={videoData.url} controls onEnded={handleVideoEnd} className={styles.video}></video>
          <div className={styles.videoInfo}>
            <h1 className={styles.title}>{videoData.title}</h1>
          </div>
          <div className={styles.sideIcons}>
            <img src={heartIcon} alt="Like" className={styles.icon} />
            <span className={styles.likesCount}>{videoData.likes}</span>
            <img src={shareIcon} alt="Share" className={styles.icon} />
          </div>
        </div>
        {showDownloadModal && <DownloadModal onClose={() => setShowDownloadModal(false)} />}
      </div>
    </section>
  )
}

export default SharedVideo

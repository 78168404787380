import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import axios from '../api/axios'
import styles from './Login.module.css'
import { useUser } from '../contexts/UserContext'
import eyeIcon from '../assets/eye_icon.svg'
import closedEyeIcon from '../assets/closed_eye_icon.svg'

const Login = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')

  const { setUser } = useUser()

  const [passwordVisible, setPasswordVisible] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible)
  }

  const MailIcon = () => (
    <svg
      className={styles.inputIcon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
    </svg>
  )

  const LockIcon = () => (
    <svg
      className={styles.inputIcon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-7h-1V7c0-2.76-2.24-5-5-5S7 4.24 7 7v3H6c-1.1 0-2 .9-2 2v9c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-9c0-1.1-.9-2-2-2zm-6 0H9V7c0-1.65 1.35-3 3-3s3 1.35 3 3v3z" />
    </svg>
  )

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.post('/api/login', {
        email,
        password
      })
      const { jwt, message } = response.data
      // console.log(response.data)
      if (jwt) {
        localStorage.setItem('jwt', jwt)
        // document.cookie = `token=${jwt}; path=/;`
        navigate('/admin/upload')
        try {
          const userDetailsResponse = await axios.get('/api/admin/v1/user')
          // Setting the user details in the UserContext
          setUser(userDetailsResponse.data)

          // console.log(userDetailsResponse.data.last_name + ' ' + userDetailsResponse.data.first_name)
        } catch (userDetailsError) {
          // console.error('Error fetching user details:', userDetailsError)
          alert(userDetailsError)
        }
      } else {
        setError(message)
      }
    } catch (error) {
      if (error.response.status === 401) {
        alert('Неверный логин или пароль.')
        setError('Неверный логин или пароль.')
        return
      }
      setError(
        `Во время обработки Вашего запроса произошла ошибка: ${
          error.response && error.response.data && error.response.data.message
            ? error.response.data.message
            : ' '
        }`
      )
    }
  }

  return (
    <div className={styles.containerForm}>
      <form className={styles.containerLoginForm} onSubmit={handleLogin}>
        <div className={styles.inputContainer}>
          <MailIcon />
          <input
            type="email"
            className={styles.inputField}
            placeholder="Почта"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className={styles.inputContainer}>
          <LockIcon />
          <input
            type={passwordVisible ? 'text' : 'password'}
            className={styles.inputField}
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <img
            src={passwordVisible ? eyeIcon : closedEyeIcon}
            alt="Toggle Password Visibility"
            className={styles.togglePasswordVisibilityIcon}
            onClick={togglePasswordVisibility}
          />
        </div>

        <button type="submit" className={styles.button}>
          Войти
        </button>
      </form>
      {error && <p>{error}</p>}
      <p>
        Нет аккаунта?{' '}
        <Link to="/admin/register">Зарегистрироваться здесь </Link>
      </p>
      <p>
        <Link to="/admin/forgot-password">Забыли пароль?</Link>
      </p>
    </div>
  )
}

export default Login

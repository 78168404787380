import React, { useState } from 'react'
import axios from '../api/axios'
import styles from './ForgotPassword.module.css'
import { Link } from 'react-router-dom'

const ForgotPassword = () => {
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const MailIcon = () => (
    <svg
      className={styles.inputIcon}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4-8 5-8-5V6l8 5 8-5v2z" />
    </svg>
  )

  const handleForgotPassword = async (e) => {
    e.preventDefault()
    try {
      await axios.post('/api/client/v1/reset-password', { email })
      setMessage('')
      setSuccessMessage('Письмо отправлено на Вашу электронную почту.')
    } catch (error) {
      setSuccessMessage('')
      const errorMessage = error.response && error.response.data && error.response.data.message
        ? error.response.data.message
        : 'Ошибка при отправке письма.'
      setMessage(errorMessage)
    }
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleForgotPassword} className={styles.form}>
        <div className={styles.inputContainer}>
          <MailIcon />
          <input
            type="email"
            className={styles.inputField}
            placeholder="Почта"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit" className={styles.button}>Отправить ссылку на сброс пароля</button>
      </form>
      {message && <p className={styles.errorMessage}>{message}</p>}
      {successMessage && <p className={styles.successMessage}>{successMessage}</p>}
      <Link className={styles.loginLink} to="/admin/login" >Войти</Link>
    </div>
  )
}

export default ForgotPassword

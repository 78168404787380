import React from 'react'
import styles from './ModalHashtag.module.css'

const ModalHashtag = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null

  return (
    <div className={styles.modalBackdrop}>
      <div className={styles.modal}>
        {children}
        <button className={styles.cancelButton} onClick={onClose}>Закрыть</button>
      </div>
    </div>
  )
}

export default ModalHashtag

import React from 'react'
import styles from './ConfirmModal.module.css'
const ConfirmationModal = ({ isOpen, onClose, onConfirm, children }) => {
  if (!isOpen) return null

  return (
        <div className={styles.modalBackdrop}>
            <div className={styles.modal}>
                {children}
                <div className={styles.buttonGroup}>
                    <button onClick={onConfirm} className={styles.confirmButton}>Да</button>
                    <button onClick={onClose} className={styles.cancelButton}>Нет</button>
                </div>
            </div>
        </div>
  )
}

export default ConfirmationModal

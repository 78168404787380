import React, { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('jwt')

  if (!token) {
    return <Navigate to="/admin/login" replace={true} />
  }

  return children
}

export default ProtectedRoute

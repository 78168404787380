import React from 'react'
import styles from './NotFound.module.css'

const NotFound = () => {
  return (
    <div className='container'>
      <div className={styles.notFoundContainer}>
        <h1 className={styles.title}>404 - Not Found</h1>
        <p className={styles.description}>Страница, которую вы ищете, не существует или была перемещена.</p>
      </div>
    </div>
  )
}

export default NotFound

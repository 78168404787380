import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from '../api/axios'
import './userDetailModal.css'

const UserDetailModal = ({ isOpen, onClose, userUuid }) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`/api/admin/v1/user/${userUuid}`)
        setUser(response.data)
      } catch (error) {
        console.error('Error fetching user detail:', error.response?.data?.message)
      }
    }

    if (isOpen && userUuid) {
      fetchUser()
    }
  }, [isOpen, userUuid])

  if (!isOpen || !user) {
    return null
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <div className="modal-overlay-user" onClick={handleOverlayClick}>
      <div className="modal-content-user">
        <button className="close-button-user" onClick={onClose}>&times;</button>
        <h2>Данные пользователя</h2>
        <ul className="user-details-list">
            <li>ID: {user.id}</li>
            <li>Роль: {user.role}</li>
            <li>Имя: {user.first_name}</li>
            <li>Фамилия: {user.last_name}</li>
            {/* <li>Фамилия: {user.last_name}</li> */}
            <li>Email: {user.email}</li>
            {/* ... more details if needed */}
          </ul>
      </div>
    </div>,
    document.body
  )
}

export default UserDetailModal

import React, { useState, useEffect, useRef } from 'react'
import styles from './Profile.module.css'
import axios from '../api/axios'
import penIcon from '../assets/pen_icon.svg'
import emailIcon from '../assets/email_icon.svg'
import { ReactComponent as BackIconSVG } from '../assets/back_icon.svg'
import userIconMin from '../assets/user_icon_min.svg'
import { useUser } from '../contexts/UserContext'
import { useNavigate } from 'react-router-dom'
import ModalHashtag from '../components/ModalHashtag'
import ChangePasswordForm from '../components/ChangePasswordForm'

// const NAME_PATTERN = new RegExp('(?:^[А-Я]{1}[а-яёА-Я]{0,30}$)|(?:^[А-Я]{1}[а-яёА-Я]*(?:[ -]{1}[А-Я]{1}[а-яёА-Я]{0,30})+$)')
const NAME_PATTERN = /^(?:[А-Я][а-яёА-Я]{0,30}$)|(?:[А-Я][а-яёА-Я]*(?:[ -][А-Я][а-яёА-Я]{0,30})+$)/
const EMAIL_PATTERN = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

const ProfileForm = () => {
  const [formData, setFormData] = useState({ first_name: '', last_name: '', email: '' })
  const [formErrors, setFormErrors] = useState({})
  const { user, setUser } = useUser()
  const navigate = useNavigate()

  const [isModalOpen, setModalOpen] = useState(false)

  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)

  const [saveStatus, setSaveStatus] = useState('')

  useEffect(() => {
    if (user) {
      setFormData({
        first_name: user.first_name || '',
        last_name: user.last_name || '',
        email: user.email || ''
      })
    }
  }, [user])

  const validateForm = () => {
    const errors = {}
    if (!NAME_PATTERN.test(formData.first_name)) {
      errors.first_name = 'Имя должно начинаться с заглавной буквы и может содержать 1 - 30 строчных букв русского алфавита. Допускаются составные имена.'
    }
    if (!NAME_PATTERN.test(formData.last_name)) {
      errors.last_name = 'Должно начинаться с заглавной буквы и может содержать 1 - 30 строчных букв русского алфавита.'
    }
    if (!EMAIL_PATTERN.test(formData.email)) {
      errors.email = 'Неверный адрес электронной почты'
    }
    return errors
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    setSaveStatus('')
    const errors = validateForm()
    if (Object.keys(errors).length === 0) {
      try {
        const response = await axios.put('/api/admin/v1/user', {
          first_name: formData.first_name,
          last_name: formData.last_name,
          email: formData.email
        })
        setUser(response.data)
        setSaveStatus('saved')
        // console.log('UpdateProfile Response', response.data);
      } catch (error) {
        // console.error('UpdateProfile Error', error.response.data);
        if (error.response && error.response.data && error.response.data.message) { alert(error.response.data.message) } else { alert(error) }
        setSaveStatus('error')
      }
    } else {
      setFormErrors(errors)
    }
  }

  const handleInputChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }))
    setFormErrors(prev => ({ ...prev, [field]: '' }))
  }

  const toggleModal = () => setModalOpen(!isModalOpen)

  return (
    <div className='container'>
      <form className={styles.userForm} onSubmit={handleSubmit}>
        {/* Name Input */}
        <div className={styles.inputGroup}>
        <img src={userIconMin} />
          <input
            type="text"
            className={styles.input}
            placeholder="Имя"
            value={formData.first_name}
            onChange={(e) => handleInputChange('first_name', e.target.value)}
            ref={firstNameRef}
          />
          <img src={penIcon} onClick={() => firstNameRef.current && firstNameRef.current.focus()} className={styles.editIcon} />
        </div>
        {formErrors.first_name && <p className={styles.errorText}>{formErrors.first_name}</p>}

        {/* Surname Input */}
        <div className={styles.inputGroup}>
          <img src={userIconMin} />
          <input
            ref={lastNameRef}
            type="text"
            className={styles.input}
            placeholder="Фамилия"
            value={formData.last_name}
            onChange={(e) => handleInputChange('last_name', e.target.value)}
          />
          <img src={penIcon} onClick={() => lastNameRef.current && lastNameRef.current.focus()} className={styles.editIcon} />
        </div>
        {formErrors.last_name && <p className={styles.errorText}>{formErrors.last_name}</p>}

        {/* Email Input */}
        <div className={styles.inputGroup}>
          <img src={emailIcon}/>
          <input
            ref={emailRef}
            type="email"
            className={styles.input}
            placeholder="Email"
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
          />
          <img src={penIcon} onClick={() => emailRef.current && emailRef.current.focus()} className={styles.editIcon} />
        </div>
        {formErrors.email && <p className={styles.errorText}>{formErrors.email}</p>}

        <div className={styles.buttonGroup}>
          <button type="button" className={styles.cancelButton} onClick={() => navigate(-1)}> <BackIconSVG className={styles.backIcon} /> Назад</button>
          <button type="submit" className={styles.submitButton}>Сохранить</button>
        </div>

        {saveStatus === 'saved' && <div className={styles.notification}>Изменения сохранены.</div>}
        {saveStatus === 'error' && <div className={styles.notification}>Произошла ошибка при сохранении. Пожалуйста, попробуйте снова.</div>}

      </form>
      <ModalHashtag isOpen={isModalOpen} onClose={toggleModal}>
        <ChangePasswordForm onClose={toggleModal} />
      </ModalHashtag>
      <div className={styles.changePwdContainer}>
        <button type="button" className={styles.changePasswordButton} onClick={toggleModal}>
          Изменить пароль
        </button>
      </div>

    </div>
  )
}

export default ProfileForm

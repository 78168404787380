import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './AdminNavbar.module.css'

const AdminNavbar = () => {
  return (
    <div className={styles.adminNavbar}>
      <div className='container'>
        <div className={styles.linkContainer}>
            <NavLink
              to="/admin/upload"
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
            >
              Загрузка видео
            </NavLink>
            <NavLink
              to="/admin/videos"
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
            >
              Список загруженных видео
            </NavLink>
            <NavLink
              to="/admin/users"
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
            >
              Пользователи
            </NavLink>
            <NavLink
              to="/admin/hashtags"
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
            >
              Хэштеги
            </NavLink>
            <NavLink
              to="/admin/educational-content"
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
            >
              Видеоуроки
            </NavLink>
            <NavLink
              to="/admin/coupons"
              className={({ isActive }) =>
                isActive ? styles.activeNavLink : styles.navLink
              }
            >
              Купоны
            </NavLink>
          </div>
        </div>
      </div>
  )
}

export default AdminNavbar

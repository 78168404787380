/* eslint-disable */
import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Button, Box } from '@mui/material';
import styles from './DiscountsList.module.css';

const columns = [
  { field: 'partner_name', headerName: 'Имя партнёра', flex: 1 },
  { field: 'name', headerName: 'Наименование скидки', flex: 1 },
  { field: 'value', headerName: 'Скидка', flex: 1 },
];

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter placeholder='Поиск...'/>
    </GridToolbarContainer>
  );
};

const DiscountsList = ({ discounts, onSelectDiscount, selectedDiscount, onClose, onSave }) => {
  const [searchText, setSearchText] = useState('');

  const filteredDiscounts = discounts.filter((discount) => {
    return (
      discount.partner_name.toLowerCase().includes(searchText.toLowerCase()) ||
      discount.name.toLowerCase().includes(searchText.toLowerCase()) ||
      discount.value.toString().includes(searchText)
    );
  });

  return (
    <div className={styles.container}>
      <DataGrid
        rows={filteredDiscounts}
        columns={columns}
        pageSize={5}
        autoHeight
        getRowId={(row) => row.uuid}
        onRowClick={(params) => onSelectDiscount(params.row)}
        getRowClassName={(params) =>
          selectedDiscount && selectedDiscount.uuid === params.row.uuid ? styles.selectedRow : ''
        }
        components={{
          Toolbar: CustomToolbar,
        }}
        sx={{
          '& .MuiDataGrid': {
            border: 'none',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none',
          },
          '& .MuiDataGrid-footerContainer': {
            '& p': {
              padding: '0 !important'
            }
          }
        }}
      />
      <div className={styles.buttonContainer}>
        <Button
          style={{ backgroundColor: 'gray', color: 'white', marginRight: '10px' }}
          onClick={onClose}
        >
          Отмена
        </Button>
        <Button
          style={{ backgroundColor: 'green', color: 'white' }}
          onClick={onSave}
        >
          Добавить
        </Button>
      </div>
    </div>
  );
};

DiscountsList.propTypes = {
  discounts: PropTypes.array.isRequired,
  onSelectDiscount: PropTypes.func.isRequired,
  selectedDiscount: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default DiscountsList;
/* eslint-disable */
import React from 'react'
import styles from './DownloadModal.module.css'
import googlePlay from '../assets/googlePlay_icon.svg'
import ruStore from '../assets/rustore_icon.svg'
// import appStore from '../assets/appstore.png'

const DownloadModal = ({ onClose }) => {
  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <button onClick={onClose} className={styles.closeButton}>&times;</button>
        <div className={styles.storeLinks}>
          {/* <a href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
            <img src={appStore} alt="Download on the App Store" />
          </a> */}
          <a href="https://apps.rustore.ru/app/ru.saasmedia.saas" target="_blank" rel="noopener noreferrer" onClick={ () => { ym(95518958, 'reachGoal', 'rustore-link'); return true; } }>
            <img src={ruStore} alt="Download on the ruStore" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=ru.saasmedia.saas" target="_blank" rel="noopener noreferrer" onClick={ () => { ym(95518958, 'reachGoal', 'google-play-link'); return true; } }>
            <img src={googlePlay} alt="Get it on Google Play" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default DownloadModal

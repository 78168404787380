import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Button } from '@mui/material'
import axios from '../api/axios'
import tableStyles from './PartnersTable.module.css'
import Modal from './Modal'
import PartnerForm from './PartnerForm'

const columns = (handleEditClick) => [
  { field: 'partnerName', headerName: 'Имя партнёра', flex: 1 },
  { field: 'legalName', headerName: 'Наименование юр.лица партнёра', flex: 1 },
  { field: 'phone', headerName: 'Телефон', flex: 1 },
  { field: 'contactPerson', headerName: 'Контактное лицо', flex: 1 },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    renderCell: (params) => (
      <button
        className={tableStyles.editBtn}
        onClick={() => handleEditClick(params.row)}
      >
        Изменить
      </button>
    )
  }
]

const CustomToolbar = ({ onAddClick }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter placeholder='Поиск...' />
      <Button variant="contained" color="success" style={{ marginLeft: 'auto' }} onClick={onAddClick}>
        Добавить
      </Button>
    </GridToolbarContainer>
  )
}

const PartnersTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [partners, setPartners] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [editingPartner, setEditingPartner] = useState(null)

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get('/api/admin/v1/partners')
        const partnersData = response.data.partners.map((partner) => ({
          id: partner.uuid,
          partnerName: partner.display_name,
          legalName: partner.official_name,
          phone: partner.contact_phone,
          contactPerson: partner.contact_person
          // inn: partner.inn,
        }))
        setPartners(partnersData)
      } catch (error) {
        setError(error.response?.data?.message || 'Произошла ошибка')
      } finally {
        setLoading(false)
      }
    }

    fetchPartners()
  }, [])

  const handleAddClick = () => {
    setEditingPartner(null)
    setIsModalOpen(true)
  }

  const handleEditClick = (partner) => {
    setEditingPartner(partner)
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handlePartnerCreated = (newPartner) => {
    const partner = {
      id: newPartner.uuid,
      partnerName: newPartner.display_name,
      legalName: newPartner.official_name,
      phone: newPartner.contact_phone,
      contactPerson: newPartner.contact_person
      // inn: newPartner.inn,
    }
    setPartners((prevPartners) => [partner, ...prevPartners])
  }

  const handlePartnerUpdated = (updatedPartner) => {
    setPartners((prevPartners) =>
      prevPartners.map((partner) =>
        partner.id === updatedPartner.uuid
          ? {
              id: updatedPartner.uuid,
              partnerName: updatedPartner.display_name,
              legalName: updatedPartner.official_name,
              phone: updatedPartner.contact_phone,
              contactPerson: updatedPartner.contact_person
              // inn: updatedPartner.inn,
            }
          : partner
      )
    )
  }

  return (
    <div style={{ width: '100%', margin: '2rem auto' }}>
      {error && <div className={tableStyles.error}>{error}</div>}
      <DataGrid
        rows={partners}
        columns={columns(handleEditClick)}
        pageSize={5}
        loading={loading}
        slots={{
          toolbar: () => <CustomToolbar onAddClick={handleAddClick} />
        }}
        sx={{
          '& .MuiDataGrid': {
            border: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-footerContainer': {
            '& p': {
              padding: '0 !important'
            }
          }
        }}
      />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {/* <h2>{editingPartner ? 'Изменить Партнёра' : 'Добавить Партнёра'}</h2> */}
        <PartnerForm
          onClose={handleCloseModal}
          onPartnerCreated={handlePartnerCreated}
          onPartnerUpdated={handlePartnerUpdated}
          initialData={editingPartner}
          partnerUuid={editingPartner?.id}
        />
      </Modal>
    </div>
  )
}

export default PartnersTable

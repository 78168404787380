import React, { useState, useEffect } from 'react'
import axios from '../../api/axios'
import { Paper, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, TextField, Typography, IconButton } from '@mui/material'
import Modal from '../Modal'
import DiscountsList from './DiscountsList'
import ConfirmationModal from '../../components/ConfirmModal'
import styles from './RewardsTable.module.css'
import editIcon from '../../assets/pen_icon.svg'

const RewardsTable = () => {
  const [rewards, setRewards] = useState([])
  const [selectedReward, setSelectedReward] = useState(null)
  const [discounts, setDiscounts] = useState([])
  const [availableDiscounts, setAvailableDiscounts] = useState([])
  const [selectedDiscount, setSelectedDiscount] = useState(null)
  const [rewardError, setRewardError] = useState('')
  const [discountError, setDiscountError] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [newRewardName, setNewRewardName] = useState('')
  const [createRewardError, setCreateRewardError] = useState('')
  const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false)
  const [editReward, setEditReward] = useState(null)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false)
  const [rewardToDelete, setRewardToDelete] = useState(null)
  const [isDiscountConfirmModalOpen, setIsDiscountConfirmModalOpen] = useState(false)
  const [discountToDelete, setDiscountToDelete] = useState(null)
  const [saveDiscountError, setSaveDiscountError] = useState('')
  const [deleteRewardError, setDeleteRewardError] = useState('')
  const [deleteDiscountError, setDeleteDiscountError] = useState('')

  useEffect(() => {
    axios.get('/api/admin/v1/rewards?showempty=true')
      .then(response => {
        setRewards(response.data)
        setRewardError('')
      })
      .catch(error => {
        setRewardError('ошибка при получении данных' + error.message)
      })
  }, [])

  useEffect(() => {
    if (selectedReward) {
      axios.get(`/api/admin/v1/reward/${selectedReward.uuid}/discounts`)
        .then(response => {
          setDiscounts(response.data)
          setDiscountError('')
        })
        .catch(error => {
          setDiscountError('ошибка при получении данных' + error.message)
        })
    } else {
      setDiscounts([])
    }
  }, [selectedReward])

  const handleRewardClick = (event, reward) => {
    event.stopPropagation()
    setSelectedReward(reward)
  }

  const handleAddReward = () => {
    setIsModalOpen(true)
  }

  const handleModalClose = () => {
    setIsModalOpen(false)
    setNewRewardName('')
    setCreateRewardError('')
    setEditReward(null)
  }

  const handleCreateReward = () => {
    if (newRewardName) {
      axios.post('/api/admin/v1/reward', { name: newRewardName })
        .then(response => {
          setRewards([response.data, ...rewards])
          handleModalClose()
        })
        .catch(error => {
          setCreateRewardError('ошибка при создании вознаграждения ' + error?.response?.data?.message)
        })
    }
  }

  const handleAddDiscount = () => {
    axios.get('/api/admin/v1/discounts')
      .then(response => {
        setAvailableDiscounts(response.data.discounts)
        setIsDiscountModalOpen(true)
        setDiscountError('')
      })
      .catch(error => {
        setDiscountError('ошибка при получении данных' + error.message)
      })
  }

  const handleDiscountModalClose = () => {
    setIsDiscountModalOpen(false)
    setSelectedDiscount(null)
    setSaveDiscountError('')
  }

  const handleSelectDiscount = (discount) => {
    setSelectedDiscount(discount)
  }

  const handleSaveDiscount = () => {
    if (selectedDiscount && selectedReward) {
      axios.put(`/api/admin/v1/reward/${selectedReward.uuid}/discount/${selectedDiscount.uuid}`)
        .then(response => {
          axios.get(`/api/admin/v1/reward/${selectedReward.uuid}/discounts`)
            .then(response => {
              setDiscounts(response.data)
              handleDiscountModalClose()
            })
            .catch(error => {
              setDiscountError('ошибка при получении данных' + error.message)
            })
        })
        .catch(error => {
          setSaveDiscountError('Ошибка при сохранении скидки: ' + error.message)
        })
    }
  }

  const handleEditReward = (reward) => {
    setEditReward(reward)
    setNewRewardName(reward.name)
    setIsModalOpen(true)
  }

  const handleUpdateReward = () => {
    if (editReward && newRewardName) {
      axios.put(`/api/admin/v1/reward/${editReward.uuid}`, { name: newRewardName })
        .then(response => {
          setRewards(rewards.map(r => (r.uuid === editReward.uuid ? response.data : r)))
          handleModalClose()
        })
        .catch(error => {
          setCreateRewardError('ошибка при обновлении вознаграждения ' + error?.response?.data?.message || error.message)
        })
    }
  }

  const handleDeleteReward = (reward) => {
    setRewardToDelete(reward)
    setIsConfirmModalOpen(true)
  }

  const confirmDeleteReward = () => {
    if (rewardToDelete) {
      axios.delete(`/api/admin/v1/reward/${rewardToDelete.uuid}`)
        .then(response => {
          setRewards(rewards.filter(r => r.uuid !== rewardToDelete.uuid))
          if (selectedReward && selectedReward.uuid === rewardToDelete.uuid) {
            setSelectedReward(null)
            setDiscounts([])
          }
          setIsConfirmModalOpen(false)
          setRewardToDelete(null)
          setDeleteRewardError('')
        })
        .catch(error => {
          setDeleteRewardError('Ошибка при удалении вознаграждения: ' + error.message)
        })
    }
  }

  const cancelDeleteReward = () => {
    setIsConfirmModalOpen(false)
    setRewardToDelete(null)
    setDeleteRewardError('')
  }

  const handleDeleteDiscount = (discount) => {
    setDiscountToDelete(discount)
    setIsDiscountConfirmModalOpen(true)
  }

  const confirmDeleteDiscount = () => {
    if (discountToDelete && selectedReward) {
      axios.delete(`/api/admin/v1/reward/${selectedReward.uuid}/discount/${discountToDelete.uuid}`)
        .then(response => {
          setDiscounts(discounts.filter(d => d.uuid !== discountToDelete.uuid))
          setIsDiscountConfirmModalOpen(false)
          setDiscountToDelete(null)
          setDeleteDiscountError('')
        })
        .catch(error => {
          setDeleteDiscountError('Ошибка при удалении скидки: ' + error.message)
        })
    }
  }

  const cancelDeleteDiscount = () => {
    setIsDiscountConfirmModalOpen(false)
    setDiscountToDelete(null)
    setDeleteDiscountError('')
  }

  return (
    <div className='container'>
      <Grid container spacing={2} style={{ height: '60vh', marginTop: '20px' }}>
        <Grid item xs={4} style={{ height: '60vh' }}>
          <Paper style={{ height: '100%' }}>
            <TableContainer component={Paper} style={{ height: '100%' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        Вознаграждения
                        <Button
                          style={{ backgroundColor: 'green', color: 'white' }}
                          onClick={handleAddReward}
                        >
                          Добавить
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rewardError
                    ? (
                    <TableRow>
                      <TableCell>{rewardError}</TableCell>
                    </TableRow>
                      )
                    : rewards.length === 0
                      ? (
                    <TableRow>
                      <TableCell style={{ borderBottom: 'none' }}>пусто</TableCell>
                    </TableRow>
                        )
                      : (
                          rewards.map((reward) => (
                      <TableRow
                        key={reward.uuid}
                        onClick={(e) => handleRewardClick(e, reward)}
                        style={{
                          cursor: 'pointer',
                          backgroundColor: selectedReward && selectedReward.uuid === reward.uuid ? '#f0f0f0' : 'white'
                        }}
                      >
                        <TableCell style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          {reward.name}
                          <div style={{ display: 'flex', gap: '8px' }}>
                            <IconButton onClick={(e) => { e.stopPropagation(); handleRewardClick(e, reward); handleEditReward(reward) }}>
                              <img src={editIcon} alt="Edit" />
                            </IconButton>
                            <IconButton onClick={(e) => { e.stopPropagation(); handleRewardClick(e, reward); handleDeleteReward(reward) }}>
                              <span className={styles.deleteIcon} />
                            </IconButton>
                          </div>
                        </TableCell>
                      </TableRow>
                          ))
                        )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={8} style={{ height: '60vh' }}>
          <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <TableContainer component={Paper} style={{ flexShrink: 0 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {selectedReward ? `Скидки для ${selectedReward.name}` : 'Выберите вознаграждение, чтобы увидеть скидки'}
                        {selectedReward && (
                          <Button
                            style={{ backgroundColor: 'green', color: 'white' }}
                            onClick={handleAddDiscount}
                          >
                            Добавить
                          </Button>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
            <TableContainer component={Paper} style={{ height: '100%', flex: 1 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Имя партнёра</TableCell>
                    <TableCell>Наименование скидки</TableCell>
                    <TableCell>Скидка</TableCell>
                    <TableCell width={50}>Действие</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {discountError
                    ? (
                    <TableRow>
                      <TableCell style={{ borderBottom: 'none' }}>{discountError}</TableCell>
                    </TableRow>
                      )
                    : discounts.length === 0
                      ? (
                    <TableRow>
                      <TableCell style={{ borderBottom: 'none' }}>нет скидок</TableCell>
                    </TableRow>
                        )
                      : (
                          discounts.map((discount) => (
                      <TableRow key={discount.uuid}>
                        <TableCell>{discount.partner_name}</TableCell>
                        <TableCell>{discount.name}</TableCell>
                        <TableCell>{discount.value}%</TableCell>
                        <TableCell>
                          <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteDiscount(discount) }}>
                            <span className={styles.deleteIcon} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                          ))
                        )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <div style={{ maxWidth: '500px', margin: '10px auto' }}>
          <h3 className={styles.formTitle}>{editReward ? 'Редактировать вознаграждение' : 'Создать новое вознаграждение'}</h3>
          <TextField
            label="Наименование вознаграждения"
            value={newRewardName}
            onChange={(e) => setNewRewardName(e.target.value)}
            fullWidth
            style={{ marginBottom: '10px' }}
          />
          {createRewardError && (
            <Typography color="error" style={{ marginTop: '10px' }}>
              {createRewardError}
            </Typography>
          )}
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '2rem' }}>
            <Button
              style={{ backgroundColor: 'gray', color: 'white' }}
              onClick={handleModalClose}
            >
              Отмена
            </Button>
            <Button
              style={{ backgroundColor: 'green', color: 'white' }}
              onClick={editReward ? handleUpdateReward : handleCreateReward}
            >
              Сохранить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isDiscountModalOpen} onClose={handleDiscountModalClose}>
        <div>
          <DiscountsList
            discounts={availableDiscounts}
            onSelectDiscount={handleSelectDiscount}
            selectedDiscount={selectedDiscount}
            onClose={handleDiscountModalClose}
            onSave={handleSaveDiscount}
          />
          {saveDiscountError && (
            <Typography color="error" style={{ marginTop: '10px', textAlign: 'center' }}>
              {saveDiscountError}
            </Typography>
          )}
        </div>
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={cancelDeleteReward}
        onConfirm={confirmDeleteReward}
      >
        <div>
          <Typography maxWidth={'400px'}>Вы уверены, что хотите удалить вознаграждение {rewardToDelete?.name}?</Typography>
          {deleteRewardError && (
            <Typography color="error" style={{ marginTop: '10px', textAlign: 'center' }}>
              {deleteRewardError}
            </Typography>
          )}
        </div>
      </ConfirmationModal>
      <ConfirmationModal
        isOpen={isDiscountConfirmModalOpen}
        onClose={cancelDeleteDiscount}
        onConfirm={confirmDeleteDiscount}
      >
        <div>
          <Typography maxWidth={'400px'}>
            Вы уверены, что хотите удалить скидку {discountToDelete?.name} из вознаграждения {selectedReward?.name}?
          </Typography>
          {deleteDiscountError && (
            <Typography color="error" style={{ marginTop: '10px', textAlign: 'center' }}>
              {deleteDiscountError}
            </Typography>
          )}
        </div>
      </ConfirmationModal>
    </div>
  )
}

export default RewardsTable

import React from 'react'
import styles from './GroupActions.module.css'

export class GroupActions extends React.Component {
  render () {
    return <div>
            {
                this.showActionButtons(this.props.video.status) &&
                <div className={styles.buttonGroup}>
                    <button
                        className={styles.editButton}
                        onClick={this.props.onClick}>
                        Изменить
                    </button>
                    <button
                        className={styles.deleteButton}
                        onClick={this.props.onClick1}>
                        Удалить
                    </button>
                </div>
            }
            {
                this.isUploaded(this.props.video.status) &&
                <div>
                  <center><div className={styles.uploader}></div></center><br></br>
                  <span className={styles.statusText}>{this.getStatus(this.props.video.status)}</span>
                </div>
            }
            {
                this.isTransforming(this.props.video.status) &&
                <div>
                  <span className={styles.statusText}>{this.getStatus(this.props.video.status)}</span>
                  <br></br>
                  <center><div className={styles.transforming}></div></center>
                </div>
            }
        </div>
  }

  getStatus (status) {
    switch (status) {
      case 'new':
        return 'Обработка...'
      case 'transforming':
        return 'Конвертация...'
      case 'transformed':
        return 'Загружено'
    }
  }

  showActionButtons (status) {
    switch (status) {
      case 'transformed':
        return true
      default:
        return false
    }
  }

  isTransforming (status) {
    switch (status) {
      case 'transforming':
        return true
      default:
        return false
    }
  }

  isUploaded (status) {
    switch (status) {
      case 'new':
        return true
      default:
        return false
    }
  }
}

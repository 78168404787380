/* eslint-disable */
import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import './dataTable.css';
import UserDetailModal from './UserDetailModal';
import { useState, useEffect } from "react";


const getColumns = (handleOpen) => [
  // Columns definition here
  {
    field: 'fullName',
    headerName: 'Имя',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 310,
    valueGetter: (params) => {return `${params.row.firstName || ''} ${params.row.lastName || ''}`}
  },
  {
    field: 'role',
    headerName: 'Роль',
    width: 190,
    // valueGetter: (params) => {return `${params.row.firstName || ''} ${params.row.lastName || ''}`}
  },
  {
    field: 'lastTime',
    headerName: 'последний сеанс',
    width: 190
  },
  {
    field: 'time',
    headerName: 'общее время',
    width: 160
  },
  {
    field: 'open',
    headerName: 'посмотреть',
    width: 120,
    sortable: false,
    renderCell: (params) => (
      <span
        className="eye-icon"
        onClick={() => handleOpen(params.row.uuid)}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z" fill="#000000"/>
    </svg>
      </span>

    )
  }
];

export default function DataGridDemo({ 
  usersData = [], 
  page, 
  pageSize, 
  totalUsers, 
  onPageChange, 
  onPageSizeChange 
}) {
  const [open, setOpen] = useState(false);
  const [selectedUserUuid, setSelectedUserUuid] = useState(null);

  const handleOpen = (userUuid) => {
    setSelectedUserUuid(userUuid);
    setOpen(true);
  };

  return (
    <Box sx={{ height: '100%', width: '100%' }}>
      <DataGrid
        className='dataGrid'
        rows={usersData}
        columns={getColumns(handleOpen)}
        // page={page}
        // pageSize={pageSize}
        // onPageSizeChange={onPageSizeChange}
        // onPageChange={onPageChange}
        // pagination
        // paginationMode="server"
        hideFooterPagination={true}
      />
      <UserDetailModal
        isOpen={open}
        onClose={() => setOpen(false)}
        userUuid={selectedUserUuid}
      />
    </Box>
  );
}
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styles from './Header.module.css'
import userIcon from '../assets/user_icon.svg'
import logo from '../assets/caac_logo.svg'
import { useUser } from '../contexts/UserContext'

const Header = () => {
  const navigate = useNavigate()
  const token = localStorage.getItem('jwt')
  const { user, setUser } = useUser()
  const handleLogout = () => {
    localStorage.removeItem('jwt')
    setUser(null)
    navigate('/admin/login')
  }

  const dropdownMenuItems = token
    ? (
    <div className={styles.dropdownContent}>
      <Link to="/admin/my-profile">Мой профиль</Link>
      <button className={styles.logoutBtn} onClick={handleLogout}>Выйти</button>
    </div>
      )
    : (
    <div className={styles.dropdownContent}>
      <Link to="/admin/login">Войти</Link>
      <Link to="/admin/register">Регистрация</Link>
    </div>
      )

  return (
    <div className={styles.navbar}>
      <div className='container'>
        <div className={styles.topRow}>
          <Link to="/admin/upload" className={styles.logo}>
            <img src={logo} alt="logo-icon" />
          </Link>
          <div className={styles.user}>
            {user && <span>{user.first_name + ' ' + user.last_name }</span>}
            <img src={userIcon} alt="user-icon" />
            {dropdownMenuItems}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header

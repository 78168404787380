import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import CouponsNavbar from '../components/CouponsNavbar'
import PartnersTable from '../components/PartnersTable'
import DiscountsTable from '../components/DiscountsTable'
import RewardsTable from '../components/rewards/RewardsTable'
import CouponsTable from '../components/CouponsTable'

const Coupons = () => {
  return (
    <div className='container'>
      <CouponsNavbar />
      <Routes>
        <Route path="coupons-list" element={<CouponsTable />} />
        <Route path="partners" element={<PartnersTable />} />
        <Route path="discounts" element={<DiscountsTable />} />
        <Route path="rewards" element={<RewardsTable />} />
        {/* <Route path="/" element={<PartnersTable />} /> */}
        <Route path="/" element={<Navigate to = "/admin/coupons/coupons-list" replace />} />

      </Routes>
    </div>
  )
}

export default Coupons

import React, { useState, useEffect } from 'react'
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Button } from '@mui/material'
import axios from '../api/axios'
import tableStyles from './DiscountsTable.module.css'
import Modal from './Modal'
import DiscountForm from './DiscountForm'
import ConfirmationModal from './ConfirmModal'

const columns = (handleEditClick, handleDeleteClick) => [
  { field: 'partnerName', headerName: 'Имя партнёра', flex: 2, minWidth: 120 },
  { field: 'name', headerName: 'Наименование скидки', flex: 2, minWidth: 120 },
  { field: 'value', headerName: 'Скидка %', flex: 1, minWidth: 87 },
  { field: 'createdAt', headerName: 'Дата создания', flex: 1, minWidth: 100 },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    width: 185,
    renderCell: (params) => (
      <div>
        <button
          className={tableStyles.editBtn}
          onClick={() => handleEditClick(params.row)}
        >
          Изменить
        </button>
        <button
          className={tableStyles.deleteBtn}
          onClick={() => handleDeleteClick(params.row.id)}
        >
          Удалить
        </button>
      </div>
    )
  }
]

const CustomToolbar = ({ onAddClick }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarQuickFilter placeholder='Поиск...' />
      <Button variant="contained" color="success" style={{ marginLeft: 'auto' }} onClick={onAddClick}>
        Добавить
      </Button>
    </GridToolbarContainer>
  )
}

const DiscountsTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [discounts, setDiscounts] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [editingDiscount, setEditingDiscount] = useState(null)
  const [deleteDiscountId, setDeleteDiscountId] = useState(null)

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const response = await axios.get('/api/admin/v1/discounts')
        const discountsData = response.data.discounts.map((discount) => ({
          id: discount.uuid,
          partnerName: discount.partner_name,
          name: discount.name,
          value: discount.value,
          createdAt: new Date(discount.created_at).toLocaleDateString('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
          })
        }))
        setDiscounts(discountsData)
      } catch (error) {
        setError(error.response?.data?.message || 'Произошла ошибка')
      } finally {
        setLoading(false)
      }
    }

    fetchDiscounts()
  }, [])

  const handleAddClick = () => {
    setEditingDiscount(null)
    setIsModalOpen(true)
  }

  const handleEditClick = (discount) => {
    setEditingDiscount(discount)
    setIsModalOpen(true)
  }

  const handleDeleteClick = (id) => {
    setDeleteDiscountId(id)
    setIsConfirmationOpen(true)
  }

  const handleConfirmDelete = async () => {
    try {
      await axios.delete(`/api/admin/v1/discount/${deleteDiscountId}`)
      setDiscounts((prevDiscounts) => prevDiscounts.filter((discount) => discount.id !== deleteDiscountId))
      setIsConfirmationOpen(false)
    } catch (error) {
      // console.log(error)
      alert('Ошибка при удалении скидки ' + error.response?.data?.message || 'Произошла ошибка')
    }
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false)
  }

  const handleDiscountCreated = (newDiscount) => {
    const discount = {
      id: newDiscount.uuid,
      partnerName: newDiscount.partnerName,
      name: newDiscount.name,
      value: newDiscount.value,
      createdAt: new Date(newDiscount.created_at).toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      })
    }
    setDiscounts((prevDiscounts) => [discount, ...prevDiscounts])
  }

  const handleDiscountUpdated = (updatedDiscount) => {
    setDiscounts((prevDiscounts) =>
      prevDiscounts.map((discount) =>
        discount.id === updatedDiscount.uuid
          ? {
              id: updatedDiscount.uuid,
              partnerName: updatedDiscount.partner_name,
              name: updatedDiscount.name,
              value: updatedDiscount.value,
              createdAt: new Date(updatedDiscount.created_at).toLocaleDateString('ru-RU', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              })
            }
          : discount
      )
    )
  }

  return (
    <div style={{ width: '100%', margin: '2rem auto' }}>
      {error && <div className={tableStyles.error}>{error}</div>}
      <DataGrid
        rows={discounts}
        columns={columns(handleEditClick, handleDeleteClick)}
        rowsPerPageOptions={[10]} // Fixed page size
        // pageSize={5}
        loading={loading}
        slots={{
          toolbar: () => <CustomToolbar onAddClick={handleAddClick} />
        }}
        sx={{
          '& .MuiDataGrid': {
            border: 'none'
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none'
          },
          '& .MuiDataGrid-cell:focus-within': {
            outline: 'none'
          },
          '& .MuiDataGrid-footerContainer': {
            '& p': {
              padding: '0 !important'
            }
          }
        }}
      />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <DiscountForm
          onClose={handleCloseModal}
          onDiscountCreated={handleDiscountCreated}
          onDiscountUpdated={handleDiscountUpdated}
          initialData={editingDiscount}
          discountUuid={editingDiscount?.id}
        />
      </Modal>
      <ConfirmationModal
        isOpen={isConfirmationOpen}
        onClose={handleCloseConfirmation}
        onConfirm={handleConfirmDelete}
      >
        Вы уверены, что хотите удалить эту скидку?
      </ConfirmationModal>
    </div>
  )
}

export default DiscountsTable

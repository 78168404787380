import React, { useEffect, useState } from 'react'
import axios from '../api/axios'
import styles from './RewardsDropdown.module.css'

const RewardsDropdown = ({ directionUuid, ticketData, setTicketData }) => {
  const [rewards, setRewards] = useState([])
  const [selectedReward, setSelectedReward] = useState('')
  const [submittedReward, setSubmittedReward] = useState('')
  const [error, setError] = useState('')

  useEffect(() => {
    // console.log(ticketData)
    const fetchRewards = async () => {
      try {
        setError('')
        const response = await axios.get('/api/admin/v1/rewards')
        setRewards(response.data)
      } catch (error) {
        if (error.response) {
          setError(error.response?.data?.message || 'Ошибка получения наград.')
        } else {
          setError('Ошибка сети')
        }
      }
    }

    const fetchTopicReward = async () => {
      try {
        const response = await axios.get(`/api/admin/v1/topic_reward?topic_uuid=${directionUuid}`)
        if (response.status === 200) {
          const topicReward = response.data
          setSelectedReward(topicReward.uuid)
          setSubmittedReward(topicReward.uuid)
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 404) {
            setError('')
          } else {
            setError(error.response?.data?.message || 'Ошибка получения вознаграждения.')
          }
        } else {
          setError('Ошибка сети')
        }
      }
    }

    fetchRewards()
    fetchTopicReward()
  }, [directionUuid])

  const handleChange = async (event) => {
    const selectedValue = event.target.value
    setSelectedReward(selectedValue)
    setError('')

    // Изменяем ticketData на основе выбранного значения
    if (selectedValue === 'none') {
      setTicketData(false)
    } else {
      setTicketData(true)
    }

    if (selectedValue) {
      if (selectedValue === 'none') {
        try {
          const response = await axios.delete(`/api/admin/v1/topic_reward?topic_uuid=${directionUuid}`)
          if (response.status === 200) {
            setSubmittedReward('')
            setError('')
          }
        } catch (error) {
          if (error.response) {
            setError(error.response.data.message || 'Ошибка удаления вознаграждения')
          } else {
            setError('Ошибка сети')
          }
        }
        return
      }
      try {
        const response = await axios.put(`/api/admin/v1/topic_reward?topic_uuid=${directionUuid}&reward_uuid=${selectedValue}`)
        if (response.status === 200) {
          setSubmittedReward(selectedValue)
          setError('')
        }
      } catch (error) {
        setSubmittedReward('')
        if (error.response) {
          setError(error.response.data.message || 'Ошибка отправки вознаграждения')
        } else {
          setError('Ошибка сети')
        }
      }
    }
  }

  return (
    <>
      {error && <p className={styles.errorText}>{error}</p>}
      <div className={styles.container}>
        <select
          value={selectedReward || 'none'}
          onChange={handleChange}
          className={`${styles.select} ${error ? styles.error : ''} ${submittedReward && selectedReward !== 'none' ? styles.selected : ''}`}
        >
          <option value="none">Вознаграждение не выбрано</option>
          {rewards.map((reward) => (
            <option key={reward.uuid} value={reward.uuid}>
              {reward.name}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export default RewardsDropdown

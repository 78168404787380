import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './CouponsNavbar.module.css'

const CouponsNavbar = () => {
  return (
    <nav className={styles.navbar}>
      <ul className={styles.navbarList}>
        <li className={styles.navbarItem}>
          <NavLink
            to="/admin/coupons/coupons-list"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Купоны
          </NavLink>
        </li>
        <li className={styles.navbarItem}>
          <NavLink
            to="/admin/coupons/partners"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Партнёры
          </NavLink>
        </li>
        <li className={styles.navbarItem}>
          <NavLink
            to="/admin/coupons/discounts"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Скидки
          </NavLink>
        </li>
        <li className={styles.navbarItem}>
          <NavLink
            to="/admin/coupons/rewards"
            className={({ isActive }) =>
              isActive ? styles.activeNavLink : styles.navLink
            }
          >
            Вознаграждения
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}

export default CouponsNavbar

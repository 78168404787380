import React, { useState, useEffect } from 'react'
import axios from '../api/axios'
import ConfirmationModal from '../components/ConfirmModal'
import styles from './HashtagList.module.css'
import Pagination from '@mui/material/Pagination'
import { useNavigate, useLocation } from 'react-router-dom'
import ModalHashtag from '../components/ModalHashtag'

const HashtagList = () => {
  const [hashtags, setHashtags] = useState([])
  const [currentHashtag, setCurrentHashtag] = useState('')
  const [currentHashtagObject, setCurrentHashtagObject] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [inputError, setInputError] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const [totalCount, setTotalCount] = useState(0)
  const limit = 10
  const [searchQuery, setSearchQuery] = useState('')
  const [lastSearchQuery, setLastSearchQuery] = useState('')

  const query = new URLSearchParams(location.search)
  const pageFromUrl = parseInt(query.get('page') || '1', 10)
  const zeroIndexedPage = pageFromUrl - 1

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false)
  const [hashtagToDelete, setHashtagToDelete] = useState(null)
  useEffect(() => {
    fetchHashtags()
  }, [zeroIndexedPage, searchQuery])

  const fetchHashtags = async () => {
    try {
      const offset = zeroIndexedPage * limit
      let apiUrl = `/api/admin/v1/tags?offset=${offset}&limit=${limit}`
      if (searchQuery) {
        apiUrl += `&query=${encodeURIComponent(searchQuery)}`
      }
      const response = await axios.get(apiUrl)
      setHashtags(response.data.tags)
      setTotalCount(response.data.count)
      setLastSearchQuery(searchQuery)
    } catch (error) {
      alert('Error fetching hashtags: ', error)
    }
  }

  const validateHashtag = (hashtag) => {
    return /^[0-9а-яёА-Я-_]{2,20}$/.test(hashtag)
  }

  const handleSave = () => {
    if (!validateHashtag(currentHashtag)) {
      setInputError(true)
      return
    }

    if (editMode) {
      // Update hashtag
      axios.put('api/admin/v1/tags', { id: currentHashtagObject.id, name: currentHashtag })
        .then(() => {
          setHashtags(hashtags.map(h => h.id === currentHashtagObject.id ? { ...h, name: currentHashtag } : h))
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            setInputError(true)
            alert(' Ошибка при создании тега, ' + '#' + currentHashtag + ' уже существует')
          } else {
            alert(error)
          }
        })
    } else {
      // Add new hashtag
      axios.post('/api/admin/v1/tags', { name: currentHashtag })
        .then(() => {
          if (pageFromUrl !== 1) { navigate('?page=1') }
          fetchHashtags()
        })
        .catch(error => {
          if (error.response && error.response.status === 400) {
            setInputError(true)
            // console.log(error.response.status)
            // console.log(error.response.data.message)
            alert(' Ошибка при создании тега, ' + '#' + currentHashtag + ' уже существует')
          } else {
            alert(error)
          }
        })
    }
    setIsModalOpen(false)
    setCurrentHashtag('')
    setInputError(false)
    setEditMode(false)
    setCurrentHashtagObject(null)
  }

  const handleEdit = (hashtag) => {
    setCurrentHashtag(hashtag.name)
    setCurrentHashtagObject(hashtag)
    setEditMode(true)
    setIsModalOpen(true)
    setInputError(false)
  }

  const openDeleteConfirm = (hashtag) => {
    setHashtagToDelete(hashtag)
    setIsDeleteConfirmOpen(true)
  }

  const handleDeleteConfirm = async () => {
    try {
      const response = await axios.delete(`api/admin/v1/tags/${hashtagToDelete.id}`)
      if (response.status === 200) {
        setHashtags(hashtags.filter(h => h.id !== hashtagToDelete.id))
        setIsDeleteConfirmOpen(false)
      }
    } catch (error) {
      alert(error.response)
    }
  }

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value)
    if (event.target.value !== lastSearchQuery && pageFromUrl !== 1) {
      navigate('?page=1')
    }
  }

  const handleChangePage = (event, value) => {
    navigate(`?page=${value}`)
  }

  const totalPages = Math.ceil(totalCount / limit)

  return (
        <div className={styles.hashtagListContainer}>
          <div className='container'>
            <div className={styles.paginationContainer}>
                <Pagination
                    count={totalPages}
                    page={pageFromUrl}
                    onChange={handleChangePage}
                    showFirstButton
                    showLastButton
                />
            </div>
            <div className={styles.topControls}>
                <div className={styles.searchContainer}>
                    <span className={styles.boldHash}>#</span>
                    <input
                        type="text"
                        maxLength={20}
                        placeholder="Поиск..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className={styles.searchInput}
                    />
                </div>
                <button onClick={() => setIsModalOpen(true)} className={`${styles.hashtagButton} ${styles.addButton}`}>Создать хэштег</button>
            </div>
            <ul className={styles.hashtagList}>
                {hashtags.map((hashtag) => (
                    <li key={hashtag.id} className={styles.hashtagItem}>
                        <span className={styles.hashtagName}>#{hashtag.name}</span>
                        <div className={styles.buttonGroup}>
                            <button className={styles.editButton} onClick={() => handleEdit(hashtag)}>Изменить</button>
                            <button className={styles.deleteButton} onClick={() => openDeleteConfirm(hashtag)}>Удалить</button>
                        </div>
                    </li>
                ))}
            </ul>
            <ConfirmationModal isOpen={isDeleteConfirmOpen} onClose={() => setIsDeleteConfirmOpen(false)} onConfirm={handleDeleteConfirm}>
                <p>Удалить этот хэштег #{hashtagToDelete ? hashtagToDelete.name : ''} ?</p>
            </ConfirmationModal>
            <ModalHashtag isOpen={isModalOpen} onClose={() => { setIsModalOpen(false); setInputError(false); setCurrentHashtag('') }}>
                <div className={styles.modal}>
                <span>#</span>
                <input
                    minLength={2}
                    maxLength={20}
                    type="text"
                    value={currentHashtag}
                    onChange={(e) => { setCurrentHashtag(e.target.value); setInputError(false) }}
                    className={`${styles.inputField} ${inputError ? styles.inputError : ''}`}
                />
                </div>
                <button onClick={handleSave} className={styles.saveButton}>Сохранить</button>
            </ModalHashtag>
            </div>
        </div>
  )
}

export default HashtagList

import React from 'react'
import ReactDOM from 'react-dom'

const Modal = ({ isOpen, onClose, videoUrl, title }) => {
  if (!isOpen) {
    return null
  }

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>&times;</button>
        <div className="video-container">
          <video className="vertical-video" controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
